<template>
  <div class="login">
    <!-- <div class="login__language">
      <span class="login__language__text" :class="{'login__language--active': language === 'en'}" @click="handleLanguage('en')">English</span>
      <span class="login__language--margin">|</span>
      <span class="login__language__text" :class="{'login__language--active': language === 'zh' || language === 'zh-CN'}" @click="handleLanguage('zh')">中文</span>
    </div> -->
    <div class="img-form">
      <img :src="require(`@/assets/logo.png`)">
      <span>Patients Management System</span>
    </div>
    <el-form
      v-if="!visible"
      class="login-form"
      label-position="top"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
    >
      <el-form-item prop="email">
        <el-input v-model="form.email" placeholder="Please Input The Email">
          <template #prefix>
            <i class="el-icon-user"></i>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input :type="inputType" v-model="form.password" placeholder="Please Input The Password">
          <template #prefix>
            <i class="el-icon-lock"></i>
          </template>
          <i
            class="iconfont"
            :class="inputType === 'password' ? 'icon-eye' : 'icon-iov-noeye'"
            slot="suffix"
            @click="changeInputType"
          >
          </i>
        </el-input>
      </el-form-item>
       <el-form-item>
         <div class="form-btn-box">
          <el-checkbox v-model="checked" size="mini">Remember Me</el-checkbox>
          <el-button type="text" @click="forgetPassWord(true)">Forgot Password?</el-button>
         </div>
      </el-form-item>
      <el-form-item>
        <el-button
          class="login-btn"
          type="primary"
          :loading="isLoginLoading"
          @click="onSubmit"
        >Login</el-button>
      </el-form-item>
    </el-form>
    <el-form
      v-else
      class="login-form"
      label-position="top"
      ref="forgetForm"
      :model="forgetForm"
      :rules="forgetRules"
      label-width="80px">
      <el-form-item prop="email">
        <el-input v-model.trim="forgetForm.email" placeholder="Please Input The Email">
          <template #prefix>
            <i class="el-icon-user"></i>
          </template>
        </el-input>
      </el-form-item>
       <el-form-item>
         <div class="form-btn-box-right">
          <!-- <el-checkbox v-model="checked" size="mini">记住我</el-checkbox> -->
          <el-button type="text" @click="forgetPassWord(false)">Login</el-button>
         </div>
      </el-form-item>
      <el-form-item>
        <el-button
          class="login-btn"
          type="primary"
          :loading="isForgetLoading"
          @click="onForget"
        >Reset Password</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login, sendResetPasswordEmail, getPermission } from '@/services'
import utils from '@/utils/Utils'
import loadingJson from '@/utils/loading.json'
// putManagerLang
export default {
  name: 'LoginIndex',
  data () {
    return {
      app_name: process.env.VUE_APP_NAME,
      inputType: 'password',
      form: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          { required: true, message: 'Please Input The Email', trigger: 'blur' },
          { pattern: /^(.+?)@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/, message: 'Please Input The Email', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please Input The Password', trigger: 'blur' }
          // { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ]
      },
      forgetRules: {
        email: [
          { required: true, message: 'Please Input The Email', trigger: 'blur' },
          { pattern: /^(.+?)@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/, message: 'Please Input The Email', trigger: 'blur' }
        ]
      },
      forgetForm: {
        email: ''
      },
      isForgetLoading: false,
      isLoginLoading: false,
      checked: false,
      visible: false
    }
  },
  created () {
    if (this.$cookies.get('history')) {
      const { email, password, checked } = this.$cookies.get('history')
      this.form = { email, password }
      this.checked = checked
    }
  },
  methods: {
    changeInputType () {
      if (this.inputType === 'password') {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
    // 切换画面
    forgetPassWord (flag) {
      if (!flag) {
        this.$refs.forgetForm.clearValidate('email')
        this.$refs.forgetForm.email = ''
      }
      this.visible = flag
    },
    // 重置
    async onForget () {
      try {
        if (await this.$refs.forgetForm.validate() === true) {
          this.isLoginLoading = true
          const data = await sendResetPasswordEmail({ user: this.forgetForm })
          if (data.code >= 200 && data.code < 300) {
            this.isLoginLoading = false
            this.$message.success('Send Success')
          }
        }
      } catch (err) {}
      this.isForgetLoading = false
    },
    async onSubmit () {
      const loading = this.$loading(loadingJson)
      try {
        //1. 表单验证
        const formvalidate = await this.$refs.form.validate()
        if (!formvalidate) return

        // 登录按钮 loading
        this.isLoginLoading = true
        // 2. 验证通过 -> 提交表单
        const { data } = await login({ user: this.form }, false)
        // 3. 处理请求结果
        //    失败：给出提示
        if (data.user) {
          this.$store.commit('user/SET_PERMIT_MENU', '')
          if (this.checked) {
            const obj = {}
            Object.assign(obj, this.form, { checked: this.checked })
            this.$cookies.set('history', JSON.stringify(obj))
          } else {
            this.$cookies.set('history', undefined)
          }
          await this.$store.dispatch('user/setUserInfo', data.user)
          await this.$store.dispatch('user/setPermitActions')
          
          if (!this.$route.query.redirect) {
            this.$store.commit('app/TOGGLE_NAME', '')
          }
          utils.requestSuccessCb(this, 'Login Success!', 0, () => {
            this.$router.push(this.$route.query.redirect || '/')
          })
        }
      } catch (err) {
        // console.log('登录失败', err)
      }
      if (loading) loading.close()
      // 结束登录按钮的 loading
      this.isLoginLoading = false
    }
  }
}
</script>

<style lang="scss">
.login {
  .el-icon-loading {
    font-size: 14px !important;
  }
  .el-checkbox {
    .el-checkbox__label {
      font-size: 12px;
    }
  }
}
</style>
<style lang="scss" scoped>
.login {
   &__language {
    margin-bottom: 10px;
    font-size: 12px;
    color: #909399;
    &--margin {
      margin: 0 5px;
    }
    &__text{
      cursor: pointer;
    }
    &--active {
      color: #40586F;
    }
  }
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient( 135deg, #d9ddd9 10%, #eaebec 100%);
  .img-form {
    width: 300px;
    padding: 30px 20px 20px 20px;
    text-align: center;
    background: rgba(255, 255 , 255, .8);
    border-radius: 5px 5px 0 0 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    > span {
      font-size: 20px;
      margin-top: 8px;
      // font-weight: 500;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }
    > img {
      // padding: 20px;
      width: 150px;
    }
  }
  .form-btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .form-btn-box-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .login-form {
    width: 300px;
    background: rgba(255, 255 , 255, .8);
    padding: 20px;
    border-radius: 0 0 5px 5px;
  }
  .login-btn {
    width: 100%;
  }
}
</style>
